.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-tertiary {
    border: 0.071em solid transparent;
    font-family: inherit;
    // font-size: 0.875em;
    font-weight: 600;
    border-radius: 0.214em;
    // padding: 1.500em 7.000em;

    padding: 0.5em 0;
    font-size: 1.475em;

    @include media-breakpoint-down(xs) {
        padding: 1.5em 0em;
    }

    .col-6 > & {
        padding: 1.5em 3em;
    }
}

.btn.btn-primary {
    color: #ffffff;
}
.btn.btn-secondary {
    color: #00264f;
}
.btn.btn-tertiary {
    color: #031921;
    background-color: #04faed;
    font-weight: 600;
    width: 100%;
    &:hover:not([disabled]):not(.disabled) {
        background: #cefbf8;
    }
}

.btn.btn-outline-primary {
    position: relative;
    font-size: 0.875em;
    font-weight: 600;
    // border: 0.08em solid;
    border: unset;
    border-radius: 5px;
    // border-radius: 0.357em;
    // @include border-linear-gradient($default-gradian-deg, $default-gradian-colors);
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='100%25' height='100%25' preserveAspectRatio='none' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3ClinearGradient x1='-20.8960364%25' y1='50.0004403%25' x2='65.6589743%25' y2='-7.02565236%25' id='linearGradient-1'%3E%3Cstop stop-color='%23F68616' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%23EE2A1E' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Crect id='Rectangle' x='0' y='0' width='100%25' height='100%25' fill='transparent' rx='5' stroke='url(%23linearGradient-1)' stroke-width='1' stroke-linejoin='round' %3E%3C/rect%3E%3C/svg%3E");
    width: 100%;

    background-size: contain;
    @media screen and (min-width: 768px) {
        background-size: 100% 100%;
    }
    transition: gradient 0.2s;

    &:not(:disabled):not(.disabled):active:focus,
    &:active,
    &:focus {
        box-shadow: none;
    }

    &.dropdown-toggle {
        &:focus {
            .show & {
                box-shadow: none;
            }
        }
    }

    &:hover {
        -webkit-text-fill-color: unset;
        -webkit-background-clip: unset;
        background-clip: unset;
        @include linear-gradient($default-gradian-deg, $default-gradian-colors);
        > span {
            color: white;
        }
    }
    &:not(:hover) > span {
        @include text-linear-gradient(
            $default-gradian-deg,
            $default-gradian-colors
        );
        line-height: 1em;
    }
}

.btn-outline-primary.dropdown-toggle {
    width: 100%;
    &:hover {
    }
}

.show > .btn-outline-primary.dropdown-toggle {
}

.btn.btn-primary {
    @include linear-gradient($default-gradian-deg, $default-gradian-colors);
    color: white;
    border: 0.063em solid transparent;
    font-weight: $font-weight-semi-bold;
    width: 100%;

    &:not(:disabled):not(.disabled):active {
        border: 0.072em solid;
        border-radius: 0.357em;
        @include text-linear-gradient(
            $default-gradian-deg,
            $default-gradian-colors
        );
        @include border-linear-gradient(
            $default-gradian-deg,
            $default-gradian-colors
        );

        &:focus {
            box-shadow: none;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        -webkit-text-fill-color: unset;
        -webkit-background-clip: unset;
        background-clip: unset;
        @include linear-gradient(-90deg, $default-gradian-colors);
        color: white;
    }
}

.btn.btn-secondary {
    background: #f0f0f0;
    font-weight: $font-weight-semi-bold;
    width: 100%;

    &:not(:disabled):not(.disabled):active {
        &:focus {
            box-shadow: none;
        }
    }

    &:focus {
        box-shadow: none;
    }
}

@media (max-width: 767.98px) {
    .btn.btn-ms {
        padding: 0.5em 0;
    }
}
