.table-history {
    border-collapse: separate;
    border-spacing: 0 1.063em;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
        display: block;
    }

    thead {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        margin-bottom: 0.563em;

        tr th {
            border: 0;
            line-height: 1.875em;
            font-weight: $font-weight-medium;
            padding: 0;
            padding-left: 1.5em;
            color: #00264f;
        }

    }

    tbody {
        @include media-breakpoint-down(sm) {
            display: block;
        }
        tr {
            @include media-breakpoint-down(sm) {
                display: block;
                margin-bottom: 1.063em;
            }
            background: #ffffff;
            box-shadow: 0 0 4.438em 0 #e0e0e0;
            border-radius: 0.813em;

            td {
                border: 0;
                line-height: 2.143em;
                font-size: 0.875em; // 14 px
                font-weight: $font-weight-regular;
                padding-left: 1.714em;
                color: #00264f;
                vertical-align: middle;

                @include media-breakpoint-down(sm) {
                    display: block;
                }

                .download {
                    .btn {
                        cursor: pointer;

                        i {
                            display: block;
                            background-size: 100% 100%;
                            height: 2.143em;
                            width: 1.929em;

                            &.btn-pdf {
                                background-image: url('/assets/img/icons/pdf.svg');
                            }

                            &.btn-html {
                                background-image: url('/assets/img/icons/html.svg');
                            }
                        }


                        &:hover {
                            i.btn-pdf {
                                background-image: url('/assets/img/icons/pdf-selected.svg');
                            }

                            i.btn-html {
                                background-image: url('/assets/img/icons/html-selected.svg');
                            }
                        }
                    }
                }

                &:first-child {
                    border-bottom-left-radius: 1.214em;
                    border-top-left-radius: 1.214em;
                }

                &:last-child {
                    border-bottom-right-radius: 1.214em;
                    border-top-right-radius: 1.214em;
                }

                @include media-breakpoint-down(sm) {
                    &:before {
                        content: attr(data-title);
                        display: block;
                        font-weight: $font-weight-medium;
                        font-family: inherit;
                        font-size: 1.143em;
                    }
                }

                &.placeholder-container {
                    width: 50%;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                    .placeholder {
                        position: relative;
                        padding: 1.438em 3.5em 1.438em 1.313em;
                        line-height: 1.250em;
                        font-size: 1.143em;
                        font-weight: 400;
                        border: 0.063em solid #cfcfcf;
                        background: #fefcfc;
                        border-radius: 0.5em;
                        width: 100%;
                        overflow-wrap: break-word;

                        /*
                        &::after {
                            @include icon-styles;
                            content: icon-char(copy);
                            @include text-linear-gradient($default-gradian-deg, $default-gradian-colors);
                            position: absolute;
                            right: 1.5em;
                            top: 1.5em;
                        }
                        */
                    }
                }
            }
        }
    }
}