.nav.nav-tabs {
    margin-bottom: 4.625em;
    border: 0;

    @include media-breakpoint-down(sm) {
        &.show-only-active-sm {
            .nav-item {
                margin-right: unset;
                a.nav-link {
                    display: none;
                    &.active {
                        display: block;
                    }
                }
            }
        }
    }

    .nav-item {
        position: relative;
        margin-right: 3.063em;

        a.nav-link {
            padding: 1.143em 0.214em;
            border: 0;
            color: #00264f;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            font-size: 0.875em;

            &.disabled {
                color: #cecece;
            }

            &::after {
                content: "";
                position: absolute;
                transition: opacity 0.2s;
                opacity: 0;
                width: 100%;
                height: 0.214em;
                bottom: 0;
                left: 0;
                @include linear-gradient($default-gradian-deg, $default-gradian-colors);
            }

            &.active {

                &::after {
                    opacity: 1;
                }

                @include text-linear-gradient($default-gradian-deg, $default-gradian-colors);
            }

            &:hover {
                @include text-linear-gradient($default-gradian-deg, $default-gradian-colors);
            }
        }
    }
}