@import '../styles/_customs.scss';
@import '../styles/_fonts.scss';
@import '../styles/_gradient.scss';

$sidebar-width: 9.688em; //155px;
$sidebar-width-sm: 7.813em; //125px
$sidebar-expand-width: 14.563em; //233px;
$sidebar-expand-width-sm: 11.25em; //180px;
$sidebar-expand-width-sm: $sidebar-expand-width;
$content-left-margin: 15.813em; //253px;
$content-left-margin-sm: 12.5em; //200px;
$content-left-margin-sm: $content-left-margin;
$sidebar-expand-top: 7.25em; //116px;
$sidebar-margin-top: 2.219em; //35.5px;
$default-gradian-deg: 90deg;
$default-gradian-colors: #f68616 0%, #ee2a1e 50%;

body {
    font-size: 16px;
}

@import '../styles/_form.scss';
@import '../styles/_table.scss';
@import '../styles/_tabs.scss';
@import '../styles/_buttons.scss';
@import '../styles/_accordion.scss';

.fixed_body {
    width: 100%;
    min-height: 100vh;
    section,
    .footer,
    .coockies-container {
        display: none !important;
    }
}

@import '~react-loading-skeleton/dist/skeleton.css';

@import '~react-notifications-component/dist/scss/notification.scss';
.notifications-component {
    // position: absolute;
    top: 0;
}

body[data-live-up='true'] {
    #lhc_container_v2 {
        #lhc_status_widget_v2,
        #lhc_widget_v2 {
            bottom: 6em !important;
        }
        #lhc_needhelp_widget_v2 {
            bottom: 9em !important;
        }
    }
}

@media (min-width: 1440px) {
    .justify-content-xxl-between {
        justify-content: space-between !important;
    }
}
