
.accordion {
    .card {
      margin-bottom: 1.500em;
      border: 0;
      box-shadow: 0 0 2.500em 0 #ebebeb;
      background: #ffffff;
      border-radius: 0.813em;
      padding: 1.250em 1.875em 1.563em 1.875em;
  
      .card-header {
        background: transparent;
        border: 0;
        padding: 0;
  
        h5 {
          font-size: 1em;
  
          button {
            font-size: 1em;
            font-weight: $font-weight-medium;
            width: 100%;
            display: block;
            text-align: left;
            padding: 0;
            display: flex;
            align-items: center;
  
            /*
            &::after {
              @include icon-styles;
              content: icon-char(caret-up);
              border: 0;
              font-size: 0.6em;
              @include text-linear-gradient($default-gradian-deg,
                $default-gradian-colors);
              margin-left: auto;
            }
            */
  
            &:hover,
            &:active:focus,
            &:focus,
            &:active {
              text-decoration: none;
  
              span {
                text-decoration: underline;
              }
            }
  
            /*
            &:not(.collapsed)::after {
              content: icon-char(caret-down);
            }
            */
  
            &:active {
              box-shadow: none;
  
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
  
      .collapsing {
        border-top: 0.063em solid #ececec;
        margin-top: 1.563em;
        padding-top: 1.313em;
      }
  
      .collapse.show {
        border-top: 0.063em solid #ececec;
        margin-top: 1.563em;
        padding-top: 1.313em;
      }
  
  
      .card-body {
        padding: 0;
        font-weight: $font-weight-light;
        line-height: 2.000em;
      }
    }
  }