.form-row {
    .form-group {
        flex: 100%;
    }

    &.padding-additional {
        margin-bottom: 2.5em;
    }

    &.quantity {
        align-items: center;

        .form-group {
            text-align: center;

            .form-control {
                padding: 1.375em 0;
                text-align: center;
            }

            .btn.btn-link {
                padding: 0.24em 0;
                width: 100%;
                @include text-linear-gradient(
                    $default-gradian-deg,
                    $default-gradian-colors
                );
                font-size: 1.563em;
                font-weight: $font-weight-semi-bold;
            }
        }
    }
}

form {
    fieldset {
        > legend {
            font-size: 1.375em;
            margin-bottom: 1.455em;
            font-weight: $font-weight-medium;
        }
    }
}

.form-group {
    position: relative;
    margin-bottom: 1em;
    text-align: left;

    .form-check:not(.toggle) {
        padding: 0.188em 0 0.188em 2.875em;
        min-height: 1.875em;
        text-align: left;

        .form-check-input {
            margin-right: 1em;
            display: none;
        }

        .form-check-label {
            color: #00264f;

            /*
            &::after {
                @include icon-styles;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                border: 0.063em solid #00264f; // 1px
                border-radius: 0.5em; // 8px
                background-color: #fefcfc;
                width: 1.875em; // 30px
                height: 1.875em; // 30px
                transition: background 0.2s;
            }
            */
        }

        /*
        .form-check-input:checked+.form-check-label:not(.toggle)::after {
            content: icon-char(check);
            background-color: #00264f;
            color: aliceblue;
            font-size: 0.563em; // 9px
            padding-top: 0.889em; // 8px
            padding-left: 0.889em; // 8px
            width: 3.333em; // 30px
            height: 3.333em; // 30px
            border-radius: 0.889em; // 8px
            border: 0.111em solid #00264f; // 1px
        }
        */
    }

    .form-check.toggle {
        padding: 0.188em 0 0.188em 0;
        min-height: 2.125em;
        margin-bottom: 1.5em;

        .form-check-input {
            margin-right: 1em;
            display: none;
        }

        .form-check-label {
            color: #00264f;
            font-weight: $font-weight-medium;
            text-align: left;
            @include media-breakpoint-down(sm) {
                display: flex;
            }

            /*
            &::after {
                @include icon-styles;
                content: icon-char(dot);
                display: inline-block;
                vertical-align: middle;
                border: 0;
                border-radius: 0.630em;
                background-color: #ffffff;
                box-shadow: 0 0 2.074em 0 #b9b9b9;
                width: 2.407em;
                height: 1.259em;
                transition: padding 0.2s, background 0.2s;
                font-size: 1.688em;
                line-height: normal;
                padding-left: 0.148em;
                padding-right: 0.148em;
                padding-top: 0.111em;
                padding-bottom: 0.111em;
                cursor: pointer;
                color: #a4a4a4;
                margin-left: 1.148em;
                @include media-breakpoint-down(sm) {
                    margin-left: auto;
                }
            }
            */
        }

        .form-check-input:checked + .form-check-label:not(.toggle)::after {
            padding-left: 1.256em;
            @include text-linear-gradient(
                $default-gradian-deg,
                $default-gradian-colors
            );
        }
    }

    label,
    input.form-control {
        transition: all 0.2s;
        touch-action: manipulation;
    }

    /**
  * Add a transition to the label and input.
  * I'm not even sure that touch-action: manipulation works on
  * inputs, but hey, it's new and cool and could remove the 
  * pesky delay.
  */
    label,
    input.form-control {
        transition: all 0.2s;
        touch-action: manipulation;
    }

    .form-select {
        position: relative;

        select.form-control {
            & + .text {
                transition: none;
            }

            &.show + .text {
                border-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            /*
            &.show+.text::after {
                content: icon-char(caret-up);
            }
            */
        }

        &.has-value label {
            opacity: 1;
            top: -0.625em;
            left: 1.5em;
            font-size: 0.8em;
            background: #fff;
            color: #00264f;
            right: unset;
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        label {
            cursor: text;
            position: absolute;
            font-size: 1em;
            background: transparent;
            left: 1.5em;
            top: 1.375em;
            right: 1.5em;
            color: #a4a4a4;
            width: auto;
        }

        .text {
            position: relative;
            font-family: 'Gilroy';
            font-weight: 400;
            border: 0.063em solid #cfcfcf;
            height: auto;
            border-radius: 0.5em;
            background-color: #fefcfc;
            -webkit-appearance: none;
            cursor: pointer;
            min-height: 3.45em;
            font-size: 1.2em;
            padding: 1em 1.5em;

            /*
            &::after {
                @include icon-styles;
                content: icon-char(caret-down);
                @include text-linear-gradient($default-gradian-deg, $default-gradian-colors);
                position: absolute;
                right: 1.500em;
                transform: scale(0.5);
                height: auto;
            }
            */
        }

        &.disabled {
            .text {
                cursor: not-allowed;
                opacity: 0.7;
            }
        }

        ul.select-content {
            position: absolute;
            z-index: 3;
            top: 4.313em;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0 1.5em;
            background-color: #fefcfc;
            width: auto;
            border: 0.063em solid #cfcfcf;
            border-top: 0;
            border-bottom-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;
            display: none;
            overflow: hidden;
            overflow-y: auto;
            max-height: 400%;

            &.show {
                display: block;
            }

            > li {
                transition: all 0.2s;
                position: relative;
                list-style-type: none;
                font-size: 1.1em;
                padding: 0.8em 0;
                color: #00264f;

                &:first-child {
                    border-top: 0.063em solid #cfcfcf;
                }

                &:hover {
                    padding: 0.8em 1.5em;
                    margin: 0 -1.5em;
                    color: #ffffff;
                    background-color: #00264f;

                    &:first-child {
                        border-top: 0.063em solid #00264f;
                    }
                }
            }
        }
    }

    input.form-control,
    textarea.form-control {
        position: relative;
        font-family: 'Gilroy';
        font-weight: 400;
        border: 0.063em solid #cfcfcf;
        height: auto;
        border-radius: 0.5em;
        // background-color: #fefcfc;
        -webkit-appearance: none;
        cursor: text;
        background: none;
        z-index: 1;
        font-size: 1.2em;
        padding: 1em 1.5em;

        &[type='password'] {
            font-size: 2.25em;
            line-height: 1em;
            padding-top: 0.389em;
            padding-bottom: 0.278em;
            padding-left: 0.667em;
            padding-right: 0.667em;
            border: 0.028em solid #cfcfcf;
            border-radius: 0.222em;

            &:focus:not([readonly]) {
                border: 0.028em solid #00264f;
            }
        }

        &:focus {
            outline: 0;
            box-shadow: none;
            background-color: #fefcfc;

            &:not([readonly]) {
                border: 0.063em solid #00264f;
            }

            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
                opacity: 0;
            }
        }

        &:not(:placeholder-shown) + label {
            opacity: 1;
            position: absolute;
            top: -0.833em;
            background: #fff;
            color: #00264f;
            right: unset;
            cursor: text;
            z-index: 2;
            padding-left: 0.5em;
            padding-right: 0.5em;
            font-size: 0.8em;
            left: 1.5em;
        }

        &:placeholder-shown + label {
            cursor: text;
            position: absolute;
            background: transparent;
            left: 1.5em;
            right: 1.5em;
            color: #a4a4a4;
            width: auto;
            font-size: 1.1em;
            top: 1.2em;
        }
    }

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder {
        opacity: 0;
        transition: inherit;
    }

    /**
  * Translate down and scale the label up to cover the placeholder,
  * when following an input (with placeholder-shown support).
  * Also make sure the label is only on one row, at max 2/3rds of the
  * field—to make sure it scales properly and doesn't wrap.
  */
    //input.form-control:placeholder-shown+label,
    /*
    input.form-control:not(:focus)+label {
      cursor: text;
      position: absolute;
      font-size: 16px;
      font-family: "Gilroy";
      font-weight: 400;
      background: transparent;
      left: 29px;
      top: 22px;
    }
    */

    /**
  * By default, the placeholder should be transparent. Also, it should 
  * inherit the transition.
  */
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder {
        opacity: 0;
        transition: inherit;
    }

    /**
  * Show the placeholder when the input is focused.
  */
    input.form-control:focus::-webkit-input-placeholder,
    textarea.form-control:focus::-webkit-input-placeholder,
    input.form-control:focus::-moz-placeholder,
    textarea.form-control:focus::-moz-placeholder,
    input.form-control:focus:-ms-input-placeholder,
    textarea.form-control:focus:-ms-input-placeholder,
    input.form-control:focus:-moz-placeholder,
    textarea.form-control:focus:-moz-placeholder {
        opacity: 0;
    }

    /**
  * When the element is focused, remove the label transform.
  * Also, do this when the placeholder is _not_ shown, i.e. when 
  * there's something in the input at all.
  */

    //input.form-control:not(:placeholder-shown)+label,
    input.form-control:focus + label,
    textarea.form-control:focus + label {
        opacity: 1;
        position: absolute;
        top: -0.833em;
        left: 1.5em;
        right: unset;
        font-size: 0.75em;
        color: #00264f;
        background: #fff;
        cursor: text;
        z-index: 2;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    input.form-control:focus-visible,
    textarea.form-control:focus-visible {
        text-shadow: none;
    }
}

.password-indicator-container {
    opacity: 0;

    .password-indicator-text {
        text-align: right;
    }

    .password-indicator {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            display: block;
            height: 0.438em;
            width: 3.563em;
            background-color: #eeeeee;
            border-radius: 0.219em;
        }

        &.weak {
            span:nth-child(1) {
                background-color: #ee2a1e;
            }
        }

        &.medium {
            span:nth-child(1),
            span:nth-child(2) {
                background-color: #ee2a1e;
            }
        }

        &.strong {
            span:nth-child(1),
            span:nth-child(2),
            span:nth-child(3) {
                background-color: #ee2a1e;
            }
        }
    }
}
