// Configuration

$violet: #00264f;

$primary: $violet;

@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/_utilities.scss";

$enable-print-styles: false;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$body-color: $violet;

$enable-shadows: true;
$enable-gradients: true;

$font-family-base: "Gilroy";

$navbar-brand-font-size: 1em;

$display1-size: 2rem;
$display2-size: 1.75rem;
$display3-size: 1.50rem;
$display4-size: 1.25rem;

$display1-weight: $font-weight-normal;
$display2-weight: $font-weight-normal;
$display3-weight: $font-weight-normal;
$display4-weight: $font-weight-normal;

$navbar-padding-y: null;
$navbar-padding-x: null;

$enable-caret: false;

$spacer: 1rem;
$spacers: (0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 2,
    6: $spacer * 2.5,
    7: $spacer * 3,
);

/* set the overriding variables */
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop,
        $abbrev in ("font-size": fs) {

            @each $size,
            $length in $spacers {
                @if ($length > 0) {
                    .#{$abbrev}#{$infix}-#{$size} {
                        #{$prop}: $length !important;
                    }
                }
            }
        }

        .lh#{$infix}-normal {
            line-height: normal !important;
        }
    }
}

$input-placeholder-color: transparent;

// Layout & components
@import "~bootstrap/scss/_root.scss";
@import "~bootstrap/scss/_reboot.scss";
@import "~bootstrap/scss/_type.scss";
@import "~bootstrap/scss/_images.scss";
@import "~bootstrap/scss/_code.scss";
@import "~bootstrap/scss/_grid.scss";
@import "~bootstrap/scss/_tables.scss";
@import "~bootstrap/scss/_forms.scss";
@import "~bootstrap/scss/_buttons.scss";
@import "~bootstrap/scss/_transitions.scss";
@import "~bootstrap/scss/_dropdown.scss";
@import "~bootstrap/scss/_button-group.scss";
@import "~bootstrap/scss/_input-group.scss";
@import "~bootstrap/scss/_custom-forms.scss";
@import "~bootstrap/scss/_nav.scss";
@import "~bootstrap/scss/_navbar.scss";
@import "~bootstrap/scss/_card.scss";
@import "~bootstrap/scss/_breadcrumb.scss";
@import "~bootstrap/scss/_pagination.scss";
@import "~bootstrap/scss/_badge.scss";
@import "~bootstrap/scss/_jumbotron.scss";
@import "~bootstrap/scss/_alert.scss";
@import "~bootstrap/scss/_progress.scss";
@import "~bootstrap/scss/_media.scss";
@import "~bootstrap/scss/_list-group.scss";
@import "~bootstrap/scss/_close.scss";
@import "~bootstrap/scss/_toasts.scss";
@import "~bootstrap/scss/_modal.scss";
@import "~bootstrap/scss/_tooltip.scss";
@import "~bootstrap/scss/_popover.scss";
@import "~bootstrap/scss/_carousel.scss";
@import "~bootstrap/scss/_spinners.scss";
@import "~bootstrap/scss/_print.scss";

@media (min-width: 320px) and (max-width: 768px) {
    .container-xxs, .container {
        max-width: unset;
    }
}


.dropdown-item.active, .dropdown-item:active
 {
    background: #04faed;
    color: #031921;
    font-weight: 600;
}
