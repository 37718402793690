/* Roboto regular */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Roboto bold */
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('/static/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
        url('/static/fonts/Gilroy/Gilroy-ExtraboldItalic.ttf')
            format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('/static/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url('/static/fonts/Gilroy/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('/static/fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url('/static/fonts/Gilroy/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('/static/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url('/static/fonts/Gilroy/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url('/static/fonts/Gilroy/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url('/static/fonts/Gilroy/Gilroy-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url('/static/fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
        url('/static/fonts/Gilroy/Gilroy-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url('/static/fonts/Gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
        url('/static/fonts/Gilroy/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url('/static/fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url('/static/fonts/Gilroy/Gilroy-UltraLightItalic.ttf')
            format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url('/static/fonts/Gilroy/Gilroy-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url('/static/fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url('/static/fonts/Gilroy/Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
        url('/static/fonts/Gilroy/Gilroy-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

/* Rubik regular */
@font-face {
    font-family: 'Rubik';
    src: url('/static/fonts/Rubik/Rubik-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Rubik bold */
@font-face {
    font-family: 'Rubik';
    src: url('/static/fonts/Rubik/Rubik-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
